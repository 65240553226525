import React from "react";
import Layout from "../../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import Minibanner1 from "../../components/Sabores/MinibannerSab1";
import Minibanner2 from "../../components/Sabores/MinibannerSab2";
import Carruselsabores from "../../components/CarruselSabores";
import CarruselCompra from "../../components/CarruselCompra";
import { useState } from "react";
import Seo from "../../components/Seo";

/* Pasos Sabores
~~1. Poner Hero
~~2. Colocar ambos minibanners
3. Agregar doble columna con imagen al hacer clic gire y texto
4. COMP - Carrusel de sabores
5. COMP - Compra
6. Estructura de texto - viñetas
7. Colocar otros dos minibanners
*/

/*return (
  <div>
    <h2>number is: {num}</h2>
    <button onClick={handleClick}>Generate random number</button>
  </div>
)*/

function Index() {
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  console.log(getRandomInt(5000));

  const [isActive, setIsActive] = React.useState(false);

  return (
    <Layout>
      <Seo
        seo={{
          metaTitle: "Sabores",
          metaDescription: "Todos los deliciosos sabores de Boone´s",
        }}
      />
      <header className="">
        <StaticImage
          src="../../assets/images/sabores/sabores/banner-pag-sabores.png"
          alt="Sabores Boones"
          className="hero-img"
          placeholder="tracedSVG"
          layout="fullWidth"
        />
      </header>
      <div className="container relative -mt-1 mb-20 md:-mt-3">
        <div className="z-10 flex flex-wrap justify-center gap-4">
          <div>
            <Minibanner1 />
          </div>
          <div>
            <Minibanner2 />
          </div>
        </div>
      </div>

      <main className="container">
        {/* RULETA 
        <div className="mb-5 flex flex-col items-center space-y-3 md:flex-row">
          <div>
            <StaticImage
              src="../../assets/images/sabores/sabores/PLwheel.png"
              alt="Sabor del día Boones"
              className="animate-spin"
              placeholder="dominantColor"
            />
          </div>
          <div className="space-x-2 text-center">
            <h5 className="uppercase"> TU SABOR </h5>
            <h1 className="uppercase text-exoticberry"> DEL DÍA </h1>
            <p className="max-w-prose text-base font-light">
              ¿Sin ideas para tu próximo coctel? Esta ruleta te podrá dar
              algunas ideas para jugar con los vibrantes sabores de Boone’s.
              Además, puedes combinar tu sabor favorito con tu platillo
              favorito.
            </p>
          </div>
        </div>*/}
        <div className="mt-10 mb-20 flex-wrap justify-center">
          <Carruselsabores />
        </div>
        <div className="mt-6 mb-20">
          <CarruselCompra />
        </div>
        <div className="mb-5 flex justify-center text-center">
          <div>
            <h5 className="uppercase"> más sobre </h5>
            <h1 className="uppercase text-deliciousapple"> Boone´s </h1>
          </div>
        </div>
        <div className="mb-5 flex flex-col items-start justify-start space-x-5 space-y-3 px-5 md:flex-row">
          <div className="max-w-prose space-y-4 px-5 text-base font-light">
            <p>
              ¡Recuerda que con Boone’s no hay reglas! Puedes crear cocteles
              refrescante gracias a sus burbujas, sabor dulce y frutal sabor con
              unos cuántos ingredientes, además si lo acompañas con postres,
              platillos salados o suaves, podrás resaltar mucho más sus sabores
              aún más.
            </p>
          </div>
          <div className="max-w-prose space-y-4 px-5 text-base font-light">
            <p>
              Recuerda que cada sabor es único, y puedes crear variaciones
              creativas para ese picnic que siempre quisiste, esa tarde de
              películas en compañía con tus amigas o ese momento para ti al lado
              de tu libro favorito.
            </p>
          </div>
        </div>
        {/*
        <div className="mt-14 flex h-64 flex-col gap-6 md:flex-row md:justify-center">
          <div className="w-full max-w-lg rounded-xl border-2 border-sunpeakpeach bg-white drop-shadow-xl">
            <StaticImage
              src="../../assets/images/sabores/sabores/PLtropical-sabordelmes.png"
              alt="Boones Sabor Del Mes"
              className="h-full w-full rounded-xl object-cover"
            />
          </div>
          <div className="w-full max-w-lg rounded-xl border-2 border-sunpeakpeach bg-white pt-4 drop-shadow-xl">
            <div className="mb-5 flex justify-center text-center">
              <div>
                <h5 className="uppercase"> encuesta </h5>
                <h3 className="uppercase text-deliciousapple">
                  ¿Cuál es tu sabor favorito?
                </h3>
              </div>
            </div>
  </div>
        </div>*/}
      </main>
    </Layout>
  );
}
export default Index;

/*
          {/* RULETA
          <Container>
            <Row xs={1} md={2}>
              <Col className="flex-center center-txt">
                <motion.div
                  initial={false}
                  onClick={() => setIsActive(!isActive)}
                  animate={{
                    rotate: isActive ? 180 : getRandomInt(5000),
                  }}
                  whileTap={{
                    x: 0,
                    y: 0,
                    scale: 1.2,
                    rotate: getRandomInt(5000),
                  }}
                >
                  <StaticImage
                    src="../../assets/images/sabores/sabores/PLwheel.png"
                    alt="bebidas preparadas con tequila"
                    className="img-banner"
                    placeholder="dominantColor"
                  />
                </motion.div>
              </Col>
              <Col className="flex-center center-txt">
                <div className="pb-2r center-txt">
                  <h5> TU SABOR </h5>
                  <h1 className="exoticBerry"> DEL DÍA </h1>
                  <p>
                    Enim minim sunt labore voluptate officia veniam cillum.
                    Dolor Lorem consectetur aliquip aute enim enim. Laborum sit
                    dolore exercitation laborum ullamco et ad ut in quis tempor
                    sint. Laborum ut voluptate tempor non proident exercitation
                    laborum amet ea fugiat in voluptate commodo.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col className="flex-center center-txt">
                <div className="pb-2r center-txt">
                  <h5> CHECA LAS </h5>
                  <h1 className="exoticBerry"> RECETAS </h1>
                  <p>
                    Enim minim sunt labore voluptate officia veniam cillum.
                    Dolor Lorem consectetur aliquip aute enim enim. Laborum sit
                    dolore exercitation laborum ullamco et ad ut in quis tempor
                    sint. Laborum ut voluptate tempor non proident exercitation
                    laborum amet ea fugiat in voluptate commodo.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          {/* Bebidas con Vodka
          <Container>
            <Row xs={1} md={2}>
              <Col className="flex-center center-txt">
                <div className="pb-2r center-txt">
                  <h5> BEBIDAS CON </h5>
                  <h1 className="pinkLemonade"> VODKA </h1>
                  <p>
                    Enim minim sunt labore voluptate officia veniam cillum.
                    Dolor Lorem consectetur aliquip aute enim enim. Laborum sit
                    dolore exercitation laborum ullamco et ad ut in quis tempor
                    sint. Laborum ut voluptate tempor non proident exercitation
                    laborum amet ea fugiat in voluptate commodo.
                  </p>
                </div>
              </Col>
              <Col className="flex-center center-txt">
                <StaticImage
                  src="../assets/images/sabores/bebidaspreparadas/vodka-img.png"
                  alt="bebidas preparadas con ron"
                  className="img-banner"
                  placeholder="dominantColor"
                />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col className="flex-center center-txt">
                <div className="pb-2r center-txt">
                  <h5> CHECA LAS </h5>
                  <h1 className="pinkLemonade"> RECETAS </h1>
                  <p>
                    Enim minim sunt labore voluptate officia veniam cillum.
                    Dolor Lorem consectetur aliquip aute enim enim. Laborum sit
                    dolore exercitation laborum ullamco et ad ut in quis tempor
                    sint. Laborum ut voluptate tempor non proident exercitation
                    laborum amet ea fugiat in voluptate commodo.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          {/* Bebidas con Ron
          <Container>
            <Row xs={1} md={2}>
              <Col className="flex-center center-txt">
                <StaticImage
                  src="../assets/images/sabores/bebidaspreparadas/ron-img.png"
                  alt="bebidas preparadas con tequila"
                  className="img-banner"
                  placeholder="dominantColor"
                />
              </Col>
              <Col className="flex-center center-txt">
                <div className="pb-2r center-txt">
                  <h5> BEBIDAS CON </h5>
                  <h1 className="sunPeakPeach"> RON </h1>
                  <p>
                    Enim minim sunt labore voluptate officia veniam cillum.
                    Dolor Lorem consectetur aliquip aute enim enim. Laborum sit
                    dolore exercitation laborum ullamco et ad ut in quis tempor
                    sint. Laborum ut voluptate tempor non proident exercitation
                    laborum amet ea fugiat in voluptate commodo.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col className="flex-center center-txt">
                <div className="pb-2r center-txt">
                  <h5> CHECA LAS </h5>
                  <h1 className="sunPeakPeach"> RECETAS </h1>
                  <p>
                    Enim minim sunt labore voluptate officia veniam cillum.
                    Dolor Lorem consectetur aliquip aute enim enim. Laborum sit
                    dolore exercitation laborum ullamco et ad ut in quis tempor
                    sint. Laborum ut voluptate tempor non proident exercitation
                    laborum amet ea fugiat in voluptate commodo.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          {/* Bebidas con Whisky
          <Container>
            <Row xs={1} md={2}>
              <Col className="flex-center center-txt">
                <div className="pb-2r center-txt">
                  <h5> BEBIDAS CON </h5>
                  <h1 className="deliciousApple"> Whisky </h1>
                  <p>
                    Enim minim sunt labore voluptate officia veniam cillum.
                    Dolor Lorem consectetur aliquip aute enim enim. Laborum sit
                    dolore exercitation laborum ullamco et ad ut in quis tempor
                    sint. Laborum ut voluptate tempor non proident exercitation
                    laborum amet ea fugiat in voluptate commodo.
                  </p>
                </div>
              </Col>
              <Col className="flex-center center-txt">
                <StaticImage
                  src="../assets/images/sabores/bebidaspreparadas/whisky-img.png"
                  alt="bebidas preparadas con Whisky"
                  className="img-banner"
                  placeholder="dominantColor"
                />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col className="flex-center center-txt">
                <div className="pb-2r center-txt">
                  <h5> CHECA LAS </h5>
                  <h1 className="deliciousApple"> RECETAS </h1>
                  <p>
                    Enim minim sunt labore voluptate officia veniam cillum.
                    Dolor Lorem consectetur aliquip aute enim enim. Laborum sit
                    dolore exercitation laborum ullamco et ad ut in quis tempor
                    sint. Laborum ut voluptate tempor non proident exercitation
                    laborum amet ea fugiat in voluptate commodo.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Wrapper>
      </main>
    </Layout>;

  .center-txt {
    text-align: center;
  }

  h5 {
    margin: 5px;
    font-weight: 500;
    letter-spacing: var(--letterSpacing);
    text-align: center;
    font-size: 1.5vh;
  }

  .pb-2r {
    padding-bottom: 2rem;
  }
  .btn {
    background: var(--exotic-berry);
  }


*/
