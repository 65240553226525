import React from "react";
import { StaticImage } from "gatsby-plugin-image";

function MinibannerSab2() {
  return (
    <div className="h-64 w-full max-w-lg rounded-xl drop-shadow-xl">
      <StaticImage
        src="../../assets/images/sabores/sabores/PLbanner-video.png"
        alt="Boones Imposible Equivocarse"
        className="h-full w-full rounded-xl object-cover"
      />
    </div>
  );
}

export default MinibannerSab2;
