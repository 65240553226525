import React from "react";

function MinibannerSab1() {
  return (
    <div className="w-full max-w-lg rounded-xl bg-white py-5 px-2 drop-shadow-xl md:h-64 md:py-10 md:px-7">
      <h1 className="text-2xl uppercase">Los sabores de Boone´s</h1>
      <h5 className="mb-5 text-xl font-light capitalize text-pinklemonade">
        Imposible Equivocarse
      </h5>
      <p className="max-w-prose text-base font-light">
        ¡En Boone’s contamos con una gran variedad de refrescantes sabores para
        ti! Gracias a su versatilidad, puedes crear bebidas preparadas con un
        toque único y frutal para un sinfín de innovadores cocteles.
      </p>
    </div>
  );
}

export default MinibannerSab1;
